const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    PHY_LOCATION_APIGATEWAY_URL: 'https://51g26fkcg5.execute-api.eu-west-1.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://bmq2ol0d4e.execute-api.eu-west-1.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://qex3rhkuec.execute-api.eu-west-1.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.3',
    HOSTNAME: 'https://locations.testing.macquarie.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.macquarie.forwoodsafety.com',
    WEBSOCKET: 'wss://hnjnmnawa0.execute-api.eu-west-1.amazonaws.com/testing'
  },
};

export default config;